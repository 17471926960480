.banner
{
    position: relative;
    overflow: hidden;
}
.banner:after
{
    content: "";
    position: absolute;
    right: -20vh;
    bottom: -20vh;
    width: 120vh;
    height: 120vh;
    border-radius: 50%;
    background: rgba(0, 0, 0, 1);
    z-index: -1;
}
.top-fixed
{
    position: absolute;
    padding: 30px 50px 0;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
}
.info
{
    margin-left: 195px;
}
.info label
{
    margin: 0;
    color: white;
    font-weight: 400;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
}
.info label + label
{
    margin-left: 40px;
}
.social
{
    margin-left: auto;
    font-size: 20px;
}
.social a i
{
    margin-right: 10px;
    color: white;
    width: 43px;
    height: 43px;
    border-radius: 50%;
    border: 2px solid white;
    padding: 10px;
    line-height: 20px;
    text-align: center;
}
.banner .full-screen
{
    min-height: 100vh;
}
.banner .me
{
    position: absolute;
    right: 0;
    width: 50vw;
    height: 100%;
    background-size: cover;
    top: 0;
    background-repeat: no-repeat;
    background-position: top left;
}
.box
{
    padding-top: 90px;
    padding-bottom: 50px;
    font-family: 'Poppins', sans-serif;
}
.box h6
{
    color: white;
    font-size: 20px;
    font-weight: 400;
    margin: 0 0 20px;
    font-family: 'Poppins', sans-serif;
}
.box h1
{
    font-size: 70px;
    font-weight: 700;
    line-height: 1;
    margin: 0 0 20px;
    color: white;
    font-family: 'Poppins', sans-serif;
}
.box .lead
{
    color: white;
    font-weight: 400;
    font-size: 18px;
    text-transform: uppercase;
    margin-bottom: 25px;
    font-family: 'Poppins', sans-serif;
}
.box .desc
{
    max-width: 450px;
    color: white;
    font-family: 'Poppins', sans-serif;
}
.btn-bar
{
    padding-top: 10px;
}
.btn-bar a
{
    color: white;
    font-size: 25px;
    line-height: 1;
    font-weight: 400;
    position: relative;
    text-decoration: none;
    padding-left: 0;
    transition: ease all 0.35s;
}
.btn-bar a:after
{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    widows: 0;
    height: 2px;
    background: currentColor;
    transition: ease all 0.35s;
}
.btn-bar a:hover
{
    padding-left: 45px;
}
.btn-bar a:hover:after
{
    width: 40px;  
}
.me{
  background-image:url(./situ.png);
}
@media (max-width: 767px)
{
    .banner .top-fixed
    {
        display: none !important;
    }
}
@media (max-width: 767px)
{
    .banner .box h6
    {
        font-size: 18px;
        margin: 0 0 10px;
    }
}
@media (max-width: 767px)
{
    .banner .box h1
    {
        font-size: 50px;
        
    }
}
@media (max-width: 767px)
{
    .banner .box .lead
    {
        font-size: 16px;
        margin-bottom: 15px;
    }
}
@media (max-width: 991px)
{
    .banner .me
    {
        opacity: 0.4;
        width: 80vw;
    }
}