.mob-header
{
    padding: 10px;
    display: none;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 11;
}

button.toggler-menu
{
    background: none;
    border: none;
}

.toggler-menu
{
    width: 40px;
    height: 40px;
    position: relative;
    top: 25px;
    margin: 0;
    border-radius: 0;
    padding: 0;
    margin-left:15px;
    border:none;
    margin-left: auto;
}
.toggler-menu span
{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 25px;
    height: 2px;
    margin-left: auto;
    background: rgb(6, 227, 243);
    color: rgb(253, 6, 150);
    box-shadow: 0px -8px 0 0 currentColor, 0px 8px 0 0 currentColor;
}

@media (max-width: 767px)
{
    .mob-header
    {
        display: block;
    } 
    .header-left
    {
        transition: ease all 0.35s;
        left: -200px;
        z-index: 222;
    }
    .header-left.menu-open
    {
        left: 0;
    }
}