html
{
    overflow-x: hidden;
}
.navbar-brand a img
{
    width: 250px;
    height: 50px;
}
body
{
    background: #10101a;
    font-family: sans-serif;
    font-size: 15px;
    font-weight: 400;
}
img
{
    max-width: 100%;
}
*
{
    outline: none !important;
}
a{
    color: #0ad6f1;
}
a:hover
{
    color: #dd2476;
    text-decoration: none;
}

