.header-left
{
    width: 80px;
    position: fixed;
    bottom: 0;
    top: 0;
    background: #0c0c14;
    border-right: 1px solid rgba(11,11,19,0,1);
    z-index: 99999;
}
.header-left .top a
{
    background: linear-gradient(315deg, #06d1f5 0%, #f503cd 74%);
    color: black;
    font-weight: 600;
    text-decoration: none;
    font-size: 40px;
    height: 80px;
    width: 80px;
    line-height: 80px;
    display: inline-block;
    vertical-align: top;
    text-align: center;
}
.header-left .nav-menu
{
    padding-top: 45%;
    padding-bottom: 45px;
}
.nav-menu li
{
    width: 100%;
}
.nav-menu li+li
{
    border-top: 1px solid rgba(255,255,255,0.1);
}
.nav-link
{
    margin: 0;
    padding: 10px 0;
    color: white;
    text-decoration: none;
    transition: ease all 0.35s;
    text-align: center;
}
.nav-link i
{
    font-size: 37px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
}

