
#about
{
    font-family: 'Poppins', sans-serif;
}
.about-me img
{
    border-radius: 50%;
    overflow: hidden;
    border: 5px solid white;
    position: relative;
}
.about-me .social-icons
{
    position: absolute;
    bottom: -15px;
    left: 0;
    right: 0;
}
.about-me .social-icons a
{
    width: 45px;
    height: 45px;
    line-height: 41px;
    background: #0b0b13;
    border: 2px solid white;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    position: relative;
    margin: 0 5px;
    transition: ease all 0.35s;
    transform: scale(1);
    margin-top: -210px;
}
.social-icons a i
{
    line-height: inherit;
}
.social-icons a:nth-child(1)
{
    top: -25px;
}
.social-icons a:nth-child(2)
{
    top: -8px;
}
.social-icons a:nth-child(4)
{
    top: -8px;
}
.social-icons a:nth-child(5)
{
    top: -25px;
}
.social-icons a:hover
{
    transform: scale(1.1);
    background: #ff01f2;
    color: white;
}
.col-lg-4 .info
{
    text-align: center;
    padding-top: 40px;
    color: white;
    width: 300px;
    margin-left: 7%;
}
.col-lg-4 .info h3
{
    font-weight: 400;
    font-size: 30px;
}
.col-lg-4 .info p
{
    margin: 0;
    font-size: 16px;
}
.info-list
{
    padding-top: 15px;
    color: white;
}
.info-list ul
{
    margin: 0;
    padding: 0;
    list-style: none;
}
.info-list li
{
    padding: 4px 0;
}
.info-list label
{
    color: white;
    margin: 0;
    font-weight: 400;
    padding-right: 10px;
}
.separated
{
    background-image: url(./bar.png);
    padding-top: 80px;
    padding-bottom: 80px;
    background-repeat: repeat-x;
    background-size: auto 6px;
    background-position: center;
}
.box1
{
    background: #0b0b13;
    padding: 40px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    z-index: 1;
}
.box1:before
{
    content: "";
    position: absolute;
    top: -220px;
    left: -220px;
    width: 200px;
    height: 200px;
    border-radius: 0;
    z-index: -1;
    background: #0b0b13;
    transition: cubic-bezier(0.39, 0.58, 0.57, 1) all 0.55s;
}
.box1:after
{
    content: "";
    position: absolute;
    top: -72px;
    left: -40px;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    z-index: -2;
    background-color: #f5d020;
    background-image: linear-gradient(315deg, #06d1f5 0%, #f503cd 74%);
    transition: cubic-bezier(0.39, 0.58, 0.57, 1) all 0.15s;
}
.box1 .fa
{
    line-height: 60px;
    font-size: 50px;
    color: white;
}
.box1 .bi
{
    line-height: 60px;
    font-size: 50px;
    color: white;
}
.box1 .content
{
    padding-top: 25px;
}
.box1 h5
{
    margin-bottom: 30px;
    color: white;
    font-size: 25px;
}
.box1:hover:before
{
    top: -72px;
    left: -40px;
    border-radius: 50%;
}
.box1:hover::after
{
    content: "";
    width: 120%;
    left: -10%;
    top: -10%;
    height: 120%;
    border-radius: 0;
    transition: cubic-bezier(0.39, 0.58, 0.57, 1) all 0.15s;
}
#wrap
{
    width: 100%;
    max-width: 1420px;
    margin: 0 auto;
    padding: 0 50px;
}
.wrapper
{
    position: relative;
    padding: 0 0 50px 0;
    margin: 0 30px;
}
.wrapper img
{
    width: 395px;
    height: 277;
}
.topbar
{
    position: relative;
    padding: 20px 0;
    margin-top: 0 0 40px;
}
.back
{
    width: 40px;
    height: 40px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -20px 0 0 -20px;
    border-radius: 50%;
    text-align: center;
    line-height: 38px;
    color: #999;
    background: #ddd;
    cursor: pointer;
    display: none;
    user-select: none
    ;
}
.tp-grid
{
    list-style: none;
    position: relative;
    display: block;
}
.tp-grid li
{
    position: absolute;
    cursor: pointer;
    border: 5px solid white;
    display: none;
    overflow: hidden;
    backface-visibility: hidden;
}
.no-js .tp-grid li
{
    position: relative;
    display: inline-block;
}
.tp-grid li a
{
    display: block;
    outline: none;
}
.tp-grid li img
{
    display: block;
    border: none;
}
.tp-info, .tp-title
{
    position: absolute;
    background: white;
    line-height: 20px;
    color: #292929;
    font-size: 22px;
    line-height: 26px;
    top: 40%;
    width: 75%;
    padding: 10px;
    font-weight: 400;
    text-align: right;
    left: -100%;
    box-shadow: 1px 1px 1px rgba(0,0,0,0.1),
                10px 0 10px -3px rgba(0,0,0,0,7),
                inset 0 0 5px rgba(0,0,0,0.04);
}

.tp-title
{
    padding: 10px 35px 10px 10px;
    left: 0px;
}
.tp-title span:nth-child(2)
{
    color: white;
}
.resume-box
{
    font-family: 'poppins',sans-serif;
}