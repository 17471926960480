.contact-info
{
    font-family: 'poppins',sans-serif;
}
.contact-info h4
{
    font-size: 34px;
    color: white;
    font-weight: 400;
}
.contact-info p
{
    font-size: 16px;
}
.contact-info ul
{
    margin: 0;
    padding: 10px 0 0;
    list-style: none;
}
.contact-info li
{
    font-size: 18px;
    font-weight: 400;
    color: white;
    line-height: 1.3;
    position: relative;
}
.contact-info li i
{
    width: 30px;
    text-align: center;
    color: #01fff2;
    font-size: 22px;
}
.contact-info li span
{
    padding-left: 10px;
}
.contact-info li + li
{
    padding-top: 20px;
}
.contact-form
{
    font-family: 'poppins',sans-serif;
}
.contact-form h4
{
    font-weight: 400;
    color: white;
    font-size: 22px;
    margin-bottom: 20px;
}
.contact-form .form-control
{
    color: white;
    background: none;
    border: 1px solid rgba(255,255,255,0.3);
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
    height: calc(2em + 0.75rem + 2px);
}
.contact-form textarea.form-control
{
    height: auto;
}
.btn-primary
{
    border: 2px solid white;
    background: transparent;
    color: white;
    padding: 15px 35px;
}
.google-map
{
    margin-top: 50px;
}
.google-map .embed
{
    filter: grayscale(1);
    -webkit-filter: grayscale(1);
}
@media (max-width: 767px)
{
    .google-map .container
    {
        height: 400px;
        width: 300px;
    }
    .google-map .embed
    {
        height: 400px;
        width: 300px;
    }
}
.google-map .embed:before
{
    padding-top: 30%;    
}